import React from 'react';

import { CreateAccountForm } from '~/components/forms';
import SignInForm from '~/components/forms/sign-in-form';
import AuthenticationLayout from '~/layouts/authentication';

const SignUp: React.FC = () => {
  return (
    <AuthenticationLayout
      title="Sign Up"
      primaryHeading="Create an Account"
      primaryDescription="Create an account to have full  access as a teacher"
      primaryForm={<CreateAccountForm />}
      secondaryHeading="I Already Have an Account"
      secondaryDescription="Sign In to your account"
      secondaryForm={<SignInForm />}
    />
  );
};

export default SignUp;
